<template>
  <div>
    <div style="height: 60px"></div>
    <iframe
      :src="pdfUrl"
      frameborder="0"
      class="box_iframe"
    ></iframe>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      pdfUrl: "",
    };
  },
  // 事件处理器
  methods: {},
  // 生命周期-实例创建完成后调用
  created() {},
  // 生命周期-实例挂载后调用
  mounted() {
    this.pdfUrl =
      this.$AES.decode_data(this.$route.query.link) +
      "?ci-process=doc-preview&dstType=html&copyable=0&htmlParams=eyJwZGZPcHRpb25zIjp7ImlzSW5TYWZlTW9kZSI6dHJ1ZX19";
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.box_iframe {
  width: 100%;
  height:95vh;
}
</style>
